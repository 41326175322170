<template>
    <form 
        :class="formStatus === 'unsent' ? 'show' : 'hide'" 
        :id="'form-' + props.formID" 
        @submit.prevent="sendFormData(props.formID)" 
        novalidate
    >
        <h3>Hast du Fragen oder Ideen?</h3>

        <p>Hier kannst du uns kontaktieren und uns dein Anliegen mitteilen.</p>

        <div class="form-field__container">
            <div
                v-if="formLoaded"
                v-for="(item, index) in forms[0].form"
                :key="index"
                class="input__container"
                :class="item[1].columnWidth === 50 ? 'with__neighbour' : ''"
                :style="item[1].columnWidth === 0 ? 'flex-basis: 100%' : 'flex-basis:' + item[1].columnWidth + '%'"
            >
                <div
                    v-if="item[1].type === 'Text'"
                    class="inner__container text"
                >

                    <label v-if="item[1].label1021" :for="'input-' + props.formID + '-' + index">
                        <h4>{{ item[1].label1021 }}<span v-if="item[1].required === 1">*</span></h4>
                    </label>

                    <input
                        type="text"
                        :id="'input-' + props.formID + '-' + index"
                        :name="item[0]"
                        :placeholder="item[1].placeholder1021 ? item[1].placeholder1021 : ''"
                        :required="item[1].required === 1 ? true : false"
                    >

                    <small class="error-message"></small>
                </div>

                <div
                    v-if="item[1].type === 'Email'"
                    class="inner__container email"
                >
                    
                    <label v-if="item[1].label1021" :for="'input-' + props.formID + '-' + index">
                        <h4>{{ item[1].label1021 }}<span v-if="item[1].required === 1">*</span></h4>
                    </label>

                    <input
                        type="email"
                        :id="'input-' + props.formID + '-' + index"
                        :name="item[0]"
                        :placeholder="item[1].placeholder1021 ? item[1].placeholder1021 : ''"
                        :required="item[1].required === 1 ? true : false"
                    >

                    <small class="error-message"></small>
                </div>

                <div
                    v-if="item[1].type === 'Checkbox'"
                    class="inner__container checkbox"
                >
                    <h4 v-if="item[1].label1021">{{ item[1].label1021 }}<span v-if="item[1].required === 1">*</span></h4>

                    <input
                        type="checkbox"
                        :id="'input-' + props.formID + '-' + index"
                        :name="item[0]"
                        :required="item[1].required === 1 ? true : false"
                    >

                    <label :for="'input-' + props.formID + '-' + index">{{ item[1].checkboxLabel1021 }}<span v-if="item[1].required === 1">*</span></label>

                    <small class="error-message"></small>
                </div>

                <div
                    v-if="item[1].type === 'Textarea'"
                    class="inner__container textarea"
                >
                    <label v-if="item[1].label1021" :for="'input-' + props.formID + '-' + index">
                        <h4>{{ item[1].label1021 }}<span v-if="item[1].required === 1">*</span></h4>
                    </label>

                    <textarea
                        :id="'input-' + props.formID + '-' + index"
                        :name="item[0]"
                        :required="item[1].required === 1 ? true : false"
                        :maxlength="item[1].maxlength"
                    >{{ item[1].placeholder1021 }}</textarea>

                    <small class="error-message"></small>
                </div>
            </div>

            <div class="candy-bag">
                <input type="text" id="input-chocolate-chips">
                <input type="text" id="input-gummy-bears" value="this-is-fine">
            </div>

            <div class="inner__container confirm-checkbox checkbox" style="flex-basis: 100%">
                <input
                    type="checkbox"
                    :id="'input-confirmation'"
                    :name="'confirmation'"
                    required
                >

                <label :for="'input-confirmation'">
                    Ich habe die <NuxtLink to="/services/data-privacy" target="_blank"><strong>Datenschutzerklärung</strong></NuxtLink> zur Kenntnis genommen.*
                </label>

                <small class="error-message"></small>
            </div>

            <p>Mit * gekennzeichnete Felder sind Pflichtfelder</p>

            <div class="submit__container">
                <button class="btn btn-primary btn-arrow" type="submit">
                    Absenden
                </button>
            </div>
        </div>
    </form>

    <div class="status-message success-message" :class="formStatus === 'success' ? 'show' : 'hide'">
        Ihre Daten wurden erfolgreich übermittelt.

        <div class="btn-container">
            <div class="btn btn-primary btn-arrow" @click="resetForm()">
                Neues Formular ausfüllen
            </div>
        </div>
    </div>

    <div class="status-message error-message" :class="formStatus === 'error' ? 'show' : 'hide'">
        Bei der Übermittlung der Daten gab es ein Problem. Bitte versuche es später noch einmal oder wende dich direkt an hein@blöd.de

        <div class="btn-container">
            <div class="btn btn-primary btn-arrow" @click="resetForm()">
                Erneut versuchen
            </div>
        </div>
    </div>
</template>

<script setup>
    import { useFormStore } from '/stores/forms'
    import { nanoid } from 'nanoid'
    import FormHandling from 'assets/js/forms.cls'

    const runtimeConfig = useRuntimeConfig()

    const props = defineProps({
        formID: Number
    })

    let formStatus = ref('unsent')

    //loader = true here

    const store = useFormStore()
    const { forms, formLoaded } = storeToRefs(store)

    // function sendFormData: sends form data on submit to post endpint
    async function sendFormData (formID) {
        const form = document.querySelector('#form-' + formID)
        const elements = form.elements
        const elementsArray = Array.prototype.slice.call(elements)
        const formCheck = new FormHandling(form, elementsArray)
        
        const jsonData = {}

        elementsArray.forEach(element => {
            switch (element.type) {
                case 'email':
                case 'text':
                case 'textarea':
                    jsonData[element.name] = element.value
                    break
                case 'checkbox':
                    jsonData[element.name] = element.checked
                    break
                case 'submit':
                    break
            }
        })

        formCheck.validateOnSubmit()

        if(form.getElementsByClassName('input-error').length > 0) {
            // put loader = false here
            return false
        }

        jsonData['messageID'] = nanoid(6)
        jsonData['formID'] = props.formID;

        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: jsonData
        }

        fetch(runtimeConfig.public.apiBase + 'v1/services/mail/incoming', requestOptions)
            .then(response => {
                switch (response.data) {
                    case 200:
                        this.formStatus = 'success'
                        break
                    case 400:
                    case 404:
                    case 500:
                        this.formStatus = 'error'
                        break
                }
            })
            .catch(error => {
                console.log(error)
            })

        //     jsonData
        // ).then(response => {
        //     switch (response.data) {
        //         case 200:
        //             this.formStatus = 'success'
        //             break
        //         case 400:
        //         case 404:
        //         case 500:
        //             this.formStatus = 'error'
        //             break
        //     }
        // })
    }

    // function resetForm: add classes to form and status-messages
    function resetForm () {
        this.formStatus = 'unsent'
    }
</script>
